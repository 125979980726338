import React from "react";
import Logo from "./logo.png";
import "./App.css";
import { motion } from "framer-motion";

const App = () => {
  return (
    <div className="App">
      {/* Hero Section */}
      <header className="hero-section">
        <div className="AppContainer">
          <motion.img
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            src={Logo}
            alt="CloudSoft Logo"
            className="AppLogo"
          />
          <motion.div
            animate={{ opacity: 1 }}
            className="AppContainerFlex"
            transition={{ duration: 1 }}
          >
            <h1 className="AppTitle">CloudSoft</h1>
            <p className="AppParagraph">
              Innovative services and software solutions
            </p>
            <div className="nav-buttons">
              <motion.a
                href="#services"
                className="nav-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Our Services
              </motion.a>
              <motion.a
                href="#about"
                className="nav-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                About Us
              </motion.a>
              <motion.a
                href="#portfolio"
                className="nav-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Our Portfolio
              </motion.a>
              <motion.a
                href="#contact"
                className="nav-button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contact Us
              </motion.a>
            </div>
          </motion.div>
        </div>
      </header>

      {/* Main Content */}
      <main>
        {/* Services Section */}
        <section id="services" className="section services-section">
          <h2 className="section-title">Our Services</h2>
          <div className="services-grid">
            <div className="service-card">
              <h3>Cloud Solutions</h3>
              <p>Secure and scalable cloud infrastructure tailored to your needs</p>
            </div>
            <div className="service-card">
              <h3>Software Development</h3>
              <p>Custom software solutions for your business requirements</p>
            </div>
            <div className="service-card">
              <h3>Digital Transformation</h3>
              <p>Modernize your business with cutting-edge technology</p>
            </div>
            <div className="service-card">
              <h3>IT Consulting</h3>
              <p>Expert guidance for your technology decisions</p>
            </div>
            <div className="service-card">
              <h3>Security and Compliance</h3>
              <p>Comprehensive security solutions and regulatory compliance services</p>
            </div>
            <div className="service-card">
              <h3>Monitoring</h3>
              <p>24/7 system monitoring and performance optimization services</p>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="section about-section">
          <h2 className="section-title">About Us</h2>
          <div className="about-content">
            <p>CloudSoft is a leading technology company specializing in innovative software solutions and cloud services. With years of experience and a team of skilled professionals, we help businesses transform their digital presence and achieve their technological goals.</p>
            <div className="stats-grid">
              <div className="stat-item">
                <h4>10+</h4>
                <p>Years Experience</p>
              </div>
              <div className="stat-item">
                <h4>100+</h4>
                <p>Projects Completed</p>
              </div>
              <div className="stat-item">
                <h4>50+</h4>
                <p>Happy Clients</p>
              </div>
            </div>
          </div>
        </section>

        {/* Portfolio Section */}
        <section id="portfolio" className="section portfolio-section">
          <h2 className="section-title">Our Portfolio</h2>
          <div className="portfolio-grid">
            <div className="portfolio-item">
              <h3>Cloud Migration</h3>
              <p>Successfully migrated small and large enterprises to cloud infrastructure</p>
            </div>
            <div className="portfolio-item">
              <h3>Custom Development</h3>
              <p>Built a tailored Custom Software solution for a growing business</p>
            </div>
            <div className="portfolio-item">
              <h3>Digital Platform</h3>
              <p>Designed and implemented scalable digital platforms tailored to meet diverse customer needs</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="section contact-section">
          <h2 className="section-title">Contact Us</h2>
          <div className="contact-content">
            <motion.a
              href="https://forms.office.com/e/t8n3sxNxD7"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get in Touch
            </motion.a>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2023 CloudSoft. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
